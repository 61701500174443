<template>
  <header class="app-header">
    <div class="app-header_top">
      <button id="menu-btn">
        <i class="fa fa-bars"></i>
      </button>
    </div>
    <div class="profile">
      <button
        v-if="profile == 1"
        @click="reloadApp"
        :disabled="loading"
        :class="
          loading
            ? 'button is-primary is-small is-loading x-self'
            : 'button is-primary is-small x-self'
        "
      >
        Recharger l'application
      </button>
      <div class="info">
        <p>
          {{ user ? user.firstname : "" }},
          <b>{{ user ? user.lastname : "" }}</b>
        </p>
        <small class="text-muted"></small>
      </div>
      <router-link class="profile-photo" to="/auth/profile">
        <img src="@/assets/logo.png" alt="logo" />
      </router-link>
    </div>
  </header>
</template>
<script>
import { mapGetters } from "vuex";
import { TYPES } from "@/store/types";
import { EventBus } from "@/utils/EventBus";
import { getAuth, mcpProfile } from "@/utils/Auth";
export default {
  data: () => ({
    user: {},
    loading: false,
    profile: ''
  }),
  computed: {
    ...mapGetters({
      accountInfo: `${TYPES.modules.USER}/${TYPES.getters.GET_ACCOUNT}`,
    }),
  },
  mounted() {
    this.profile = mcpProfile();
    this.user = this.accountInfo;
    EventBus.$on("updateProfile", this.getInfo);
    EventBus.$on("avatar", this.updateAvatar);
  },
  methods: {
    getInfo(user) {
      this.user = user;
    },
    updateAvatar(avatar) {
      let user = getAuth();
      user.photo = avatar;
      localStorage.setItem("mcp", JSON.stringify(user));
      this.user = getAuth();
    },
    reloadApp() {
      this.loading = true;
      this.$store
        .dispatch(`${TYPES.modules.USER}/${TYPES.actions.RELOAD_APP}`)
        .then((res) => {
          if (res.status === true) {
            this.loading = false;
            this.$toast.success("Application rechargé");
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.x-self {
  align-self: center;
}
</style>