<template>
  <aside>
    <div class="top">
      <div class="logo">
        <img alt="logo" src="@/assets/logo.png" />
        <h2 id="sptext">MOBILE<span class="danger">Crosspay</span></h2>
      </div>
      <div class="close" id="close-btn">
        <i class="fa fa-times"></i>
      </div>
    </div>
    <div class="sidebar">
      <router-link :to="menu.path" :key="key" v-for="(menu, key) in menus">
        <i :class="menu.icon"></i>
        <h3>{{ menu.name }}</h3>
      </router-link>
      
      <!-- <router-link to="/">
        <i class="fa fa-call"></i>
        <h3>Support</h3>
        <span class="message-count">26</span>
      </router-link> -->

      <a href="#" @click.prevent="logoutUser">
        <i class="fa fa-sign-out"></i>
        <h3>Déconnexion</h3>
      </a>
    </div>
  </aside>
</template>
<script>
import { TYPES } from "@/store/types";
import { mcpProfile } from "@/utils/Auth";
import getItemMenu from './menuItem'
export default {
  data: () => ({
    p: null,
    op: null,
    menus: []
  }),
  methods: {
    logoutUser() {
      this.$store
        .dispatch(`${TYPES.modules.USER}/${TYPES.actions.LOGOUT_USER}`)
        .then((res) => {
          if (res) {
            window.location.reload();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  mounted() {
    this.menus = getItemMenu(mcpProfile())
  },
};
</script>