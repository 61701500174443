const AdminMenu = [
    { name: 'Tableau de bord', icon: 'fa fa-home', path: '/' },
    { name: 'Clients', icon: 'fa fa-users', path: '/customers' },
    { name: 'Opérateurs', icon: 'fa fa-bank', path: '/operateurs' },
    { name: 'Services', icon: 'fa-brands fa-app-store', path: '/services' },
    { name: 'Reversement', icon: 'fa-solid fa-coins', path: '/refunds' },
    { name: 'Service support', icon: 'fa-solid fa-clipboard-list', path: '/request-support' },
    { name: 'Transactions', icon: 'fa-solid fa-tent-arrow-left-right', path: '/transactions' },
    { name: 'Utilisateurs', icon: 'fa-solid fa-users', path: '/users' },
];


const ClientMenu = [
    { name: 'Tableau de bord', icon: 'fa fa-home', path: '/' },
    { name: 'Services', icon: 'fa-brands fa-app-store', path: '/services' },
    { name: 'Reversement', icon: 'fa-solid fa-coins', path: '/refunds' },
    { name: 'Service support', icon: 'fa-solid fa-clipboard-list', path: '/request-support' },
    { name: 'Transactions', icon: 'fa-solid fa-tent-arrow-left-right', path: '/transactions' },
];

const OperatorMenu = [
    { name: 'Transactions', icon: 'fa-solid fa-tent-arrow-left-right', path: '/transactions' }
]



const getItemMenu = (profile) => {
    let menu = [];
    switch(profile){
        case 1:
            menu = AdminMenu; break;
        case 0:
            menu = ClientMenu; break;  
        case 2:
            menu = OperatorMenu; break;
    }
    return menu
}

export default getItemMenu;