<template>
  <div class="container-box">
    <menu-section />
    <main>
      <header-section />
      <div class="app-content">
        <router-view />
      </div>
    </main>
  </div>
</template>

<script>
import MenuSection from "./partials/menu.vue";
import HeaderSection from "./partials/header.vue";
import FooterSection from "./partials/footer.vue";
export default {
  components: {
    MenuSection,
    HeaderSection,
    FooterSection,
  },
  mounted() {
    setTimeout(() => {
      const sideMenu = document.querySelector("aside");
      const menuBtn = document.querySelector("#menu-btn");
      const closeBtn = document.querySelector("#close-btn");
      menuBtn.addEventListener("click", () => {
        sideMenu.style.display = "block";
      });
      closeBtn.addEventListener("click", () => {
        sideMenu.style.display = "";
      });
    }, 3000);
  },
};
</script>

<style lang="scss" scoped>
</style>